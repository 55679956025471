// ----------------------------------
// Custom Mixin for once.plone6theme
// ----------------------------------

// use: @include link-hf {}
@mixin link-hf () {
     &:hover,
     &:focus { @content }
}

// use: @include link-haf {}
@mixin link-haf () {
     &:hover,
     &:active,
     &:focus { @content }
}