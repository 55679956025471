// ------------------------------------------
// ------------- VARIABLES ------------------
// ------------------------------------------

// Colores
$once-text-color:   #333;
$amarillo:          #ffd600;
$amarillo-claro:    #f1e2a6;
$verde-ilusion:     #007B22;
$verde-claro:       #eff7f2;
$gris-oscuro-20:    #202020;
$gris-oscuro-30:    #303030;
$gris-oscuro-40:    #404040;
$gris-claro:        #CCC;
$gris-ddd:          #DDD;
$gris-muy-claro:    #EEE;
$gray-900:          #222921;


// Implementamos un nuevo hiddenStructure
%element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}
%element-oculto {display:none;visibility:hidden;}
%element-mostrado {display:block;visibility:visible;}