// --------- ATENCION: ---------------------
// NO USAR tildes ni caracteres extranos en los comentarios
// Esto aniade una linea de chartset al inicio de la css compilada que
// genera un error

// ------------------------------------------
// ----------- importaciones ----------------
// ------------------------------------------

@import "variables";
@import "custom-mixin";

/* --------------- */
/* Capas generales */
/* --------------- */

#portal-header-titleImageSS {
  position: relative;
  z-index: 1;

  #titleImageSS-wrapper {
    position: relative;
    z-index: 1;
  }

  #carousel-wrapper {margin: 0; padding: 0;}

  ul.sshow li.sshow-carousel {
    position: relative;
    height: 100%;
    display: none;
    visibility: hidden;
  }

  ul.sshow li.loading {
    display: none;
  }


  div.oneSlide {
    position: relative;
    height: 100%;
  }

  ul.sshow li.sshow-carousel.mostrarcarousel {
    display: block;
    visibility: visible;
    animation-name: animationcarousel;
    animation-duration: 600ms;
    animation-iteration-count: 1;
    -ms-animation-name: animationcarousel;
    -ms-animation-duration: 600ms;
    -ms-animation-iteration-count: 1;
    -webkit-animation-name: animationcarousel;
    -webkit-animation-duration: 600ms;
    -webkit-animation-iteration-count: 1;
    -moz-animation-name: animationcarousel;
    -moz-animation-duration: 600ms;
    -moz-animation-iteration-count: 1;
    -o-animation-name: animationcarousel;
    -o-animation-duration: 600ms;
    -o-animation-iteration-count: 1;

    // para cuando no hay slides para movil
    min-height: 10em;
  }

  ul.sshow li.sshow-carousel.ocultarcarousel {
    animation-name: animationcarouselHide;
    animation-duration: 1000ms;
    animation-iteration-count: 1;
    -ms-animation-name: animationcarouselHide;
    -ms-animation-duration: 1000ms;
    -ms-animation-iteration-count: 1;
    -webkit-animation-name: animationcarouselHide;
    -webkit-animation-duration: 1000ms;
    -webkit-animation-iteration-count: 1;
    -moz-animation-name: animationcarouselHide;
    -moz-animation-duration: 1000ms;
    -moz-animation-iteration-count: 1;
    -o-animation-name: animationcarouselHide;
    -o-animation-duration: 1000ms;
    -o-animation-iteration-count: 1;
    display: none;
    visibility: hidden;
  }

  /* -------------------------------------------------- */
  /* -------------------------------------------------- */
  /* Es necesario crear la siguiente animación
   en una hoja de estilos diferente para que
   funcione correctamente en todos los navegadores */
  /* Estas dos animaciones estan en animations.css
   del paquete ilusion.theme */
  /* Para proyectos del futuro que no usen ilusion.theme
   es necesario copiar y pegar en otra hoja de estilos */
  /*
@keyframes animationcarousel {
    0% {opacity:0;}
    40% {opacity:0;}
    100% {opacity:1;}
}

@keyframes animationcarouselHide {
    0% {opacity:1; }
    40% {opacity:0;}
    100% {opacity:0;}
} */
  /* -------------------------------------------------- */
  /* -------------------------------------------------- */

  /* Capa Textos */
  .titleImageSS-box-texts {
    color: white;
    width: 100%;
    position: absolute;
    z-index: 2;
    word-wrap: break-word;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 0 3em;
    box-sizing: border-box;
    display: inline-flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    align-items: flex-end;
  }

  .titleImageSS-box-texts .box-texts-wrapper {
    position: relative;
    width: 100%;
    box-sizing: border-box;
  }

  .titleImageSS-box-texts .box-texts-wrapper .wrap-content {
    overflow: hidden;
    max-width: 22em;
    padding: 1em;
  }
  .wrap-content-left .titleImageSS-box-texts .box-texts-wrapper .wrap-content {
    text-align: left;
  }
  .wrap-content-center
    .titleImageSS-box-texts
    .box-texts-wrapper
    .wrap-content {
    margin: 0 auto;
    text-align: center;
  }
  .wrap-content-right .titleImageSS-box-texts .box-texts-wrapper .wrap-content {
    float: right;
    text-align: right;
    max-width: 20em;
  }

  .titleImageSS-box-texts .title-titleImageSS {
    font-family: Avenir Next W01, Arial, FreeSans, sans-serif;
    font-size: 2.15em;
    display: block;
    font-weight: 700;
  }

  /* Tamanio de la fuente */
  .wfontSize-Oculto .titleImageSS-box-texts .title-titleImageSS {
    @extend %element-invisible;
  }
  .wfontSize-8 .titleImageSS-box-texts .title-titleImageSS {
    font-size: 8pt;
    line-height: 8pt;
  }
  .wfontSize-10 .titleImageSS-box-texts .title-titleImageSS {
    font-size: 10pt;
    line-height: 10pt;
  }
  .wfontSize-11 .titleImageSS-box-texts .title-titleImageSS {
    font-size: 11pt;
    line-height: 11pt;
  }
  .wfontSize-12 .titleImageSS-box-texts .title-titleImageSS {
    font-size: 12pt;
    line-height: 12pt;
  }
  .wfontSize-14 .titleImageSS-box-texts .title-titleImageSS {
    font-size: 14pt;
    line-height: 14pt;
  }
  .wfontSize-18 .titleImageSS-box-texts .title-titleImageSS {
    font-size: 18pt;
    line-height: 18pt;
  }
  .wfontSize-24 .titleImageSS-box-texts .title-titleImageSS {
    font-size: 24pt;
    line-height: 24pt;
  }
  .wfontSize-30 .titleImageSS-box-texts .title-titleImageSS {
    font-size: 30pt;
    line-height: 30pt;
  }
  .wfontSize-36 .titleImageSS-box-texts .title-titleImageSS {
    font-size: 36pt;
    line-height: 36pt;
  }
  .wfontSize-48 .titleImageSS-box-texts .title-titleImageSS {
    font-size: 48pt;
    line-height: 48pt;
  }
  .wfontSize-60 .titleImageSS-box-texts .title-titleImageSS {
    font-size: 60pt;
    line-height: 60pt;
  }
  .wfontSize-72 .titleImageSS-box-texts .title-titleImageSS {
    font-size: 72pt;
    line-height: 72pt;
  }

  .wfontSizeEntradilla-Oculto .titleImageSS-box-texts .title-cuerpoImageSS {
    @extend %element-invisible;
  }
  .wfontSizeEntradilla-8 .titleImageSS-box-texts .title-cuerpoImageSS {
    font-size: 8pt;
  }
  .wfontSizeEntradilla-10 .titleImageSS-box-texts .title-cuerpoImageSS {
    font-size: 10pt;
  }
  .wfontSizeEntradilla-11 .titleImageSS-box-texts .title-cuerpoImageSS {
    font-size: 11pt;
  }
  .wfontSizeEntradilla-12 .titleImageSS-box-texts .title-cuerpoImageSS {
    font-size: 12pt;
  }
  .wfontSizeEntradilla-14 .titleImageSS-box-texts .title-cuerpoImageSS {
    font-size: 14pt;
  }
  .wfontSizeEntradilla-18 .titleImageSS-box-texts .title-cuerpoImageSS {
    font-size: 18pt;
  }
  .wfontSizeEntradilla-24 .titleImageSS-box-texts .title-cuerpoImageSS {
    font-size: 24pt;
  }
  .wfontSizeEntradilla-30 .titleImageSS-box-texts .title-cuerpoImageSS {
    font-size: 30pt;
  }
  .wfontSizeEntradilla-36 .titleImageSS-box-texts .title-cuerpoImageSS {
    font-size: 36pt;
  }
  .wfontSizeEntradilla-48 .titleImageSS-box-texts .title-cuerpoImageSS {
    font-size: 48pt;
  }
  .wfontSizeEntradilla-60 .titleImageSS-box-texts .title-cuerpoImageSS {
    font-size: 60pt;
  }
  .wfontSizeEntradilla-72 .titleImageSS-box-texts .title-cuerpoImageSS {
    font-size: 72pt;
  }

  /* Capa link */
  .wrap-content-left .titleImageSS-box-link {
    text-align: left;
  }
  .wrap-content-center .titleImageSS-box-link {
    text-align: center;
  }
  .wrap-content-right .titleImageSS-box-link {
    text-align: right;
  }

  .titleImageSS-box-link a.carouselTextLink,
  .titleImageSS-box-link span.carouselTextLink {
    color: $once-text-color;
    display: inline-block;
    font-weight: bold;
    margin-top: 0.2em;

    @include link-haf {color: white;}

  }

  .wrap-content-center .titleImageSS-box-link a.carouselTextLink,
  .wrap-content-center .titleImageSS-box-link span.carouselTextLink {
    max-width: 20em;
  }

  /* Capa imagen */
  .titleImageSS-box-image {
    height: 100%;
    max-height: 50em;
    text-align: left;
    position: relative;
    overflow: hidden;
    display: inline-flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
    align-content: center;

    img,
    picture {
      width: 100%;
      align-self: center;
    }

    @media screen and (max-width: 767px) {
      align-items: center;
      flex-direction: column;

      picture {display: contents;}

      img {
        width: 48em;
        max-width: none;
      }
    }
  }

  /* Capa Enlace de Video */
  .titleImageSS-box-video .wrap-content {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
  }
  .wvideo-left-up .titleImageSS-box-video .wrap-content {
    text-align: left;
    top: 0.8em;
  }
  .wvideo-left-down .titleImageSS-box-video .wrap-content {
    text-align: left;
    bottom: 0.8em;
    padding-left: 24em;
  }
  .wvideo-center-up .titleImageSS-box-video .wrap-content {
    text-align: center;
    margin: 0 auto;
    top: 0.8em;
  }
  .wvideo-center-down .titleImageSS-box-video .wrap-content {
    text-align: center;
    margin: 0 auto;
    bottom: 0.8em;
  }
  .wvideo-right-up .titleImageSS-box-video .wrap-content {
    position: absolute;
    text-align: right;
    top: 0.8em;
  }
  .wvideo-right-down .titleImageSS-box-video .wrap-content {
    position: absolute;
    text-align: right;
    bottom: 0.8em;
  }

  .titleImageSS-box-video .wrap-content a {
    font-size: 1.25em;
    display: inline-block;
    color: black;
    padding: 0.4em 0.4em 0.4em 2.2em;
    line-height: 110%;
    text-transform: uppercase;
    background: rgba(255,255,255, 0.8) url(play-button-black.png) no-repeat
      scroll 0.4em center / 1.4em auto;
  }

  .titleImageSS-box-video .wrap-content a:hover,
  .titleImageSS-box-video .wrap-content a:active,
  .titleImageSS-box-video .wrap-content a:focus {
    text-decoration: none;
    border: 0.125rem solid white;
    outline: 0.125rem solid $gray-900;
    color: white;
    background: $verde-ilusion url(play-button.png) no-repeat 0.4em center/1.4em auto;
  }
}

// ----------------------------------------------
// Familia de la fuente
// ----------------------------------------------

#portal-header-titleImageSS
  .wfont-font1
  .titleImageSS-box-texts
  .title-titleImageSS,
#form-widgets-fonts > option[value="font1"] {
  font-family: Avenir Next W01, Arial, FreeSans, sans-serif;
}

#portal-header-titleImageSS
  .wfontEntradilla-font1
  .titleImageSS-box-texts
  .title-cuerpoImageSS,
#form-widgets-fonts_entradilla > option[value="font1"] {
  font-family: Avenir W01, Arial, FreeSans, sans-serif;
}

#portal-header-titleImageSS
  .wfont-font2
  .titleImageSS-box-texts
  .title-titleImageSS,
#portal-header-titleImageSS
  .wfontEntradilla-font2
  .titleImageSS-box-texts
  .title-cuerpoImageSS,
#form-widgets-fonts > option[value="font2"],
#form-widgets-fonts_entradilla > option[value="font2"] {
  font-family: Arial, FreeSans, sans-serif;
}

#portal-header-titleImageSS
  .wfont-font3
  .titleImageSS-box-texts
  .title-titleImageSS,
#portal-header-titleImageSS
  .wfontEntradilla-font3
  .titleImageSS-box-texts
  .title-cuerpoImageSS,
#form-widgets-fonts > option[value="font3"],
#form-widgets-fonts_entradilla > option[value="font3"] {
  font-family: Verdana, FreeSans, sans-serif;
}

#portal-header-titleImageSS
  .wfont-font4
  .titleImageSS-box-texts
  .title-titleImageSS,
#portal-header-titleImageSS
  .wfontEntradilla-font4
  .titleImageSS-box-texts
  .title-cuerpoImageSS,
#form-widgets-fonts > option[value="font4"],
#form-widgets-fonts_entradilla > option[value="font4"] {
  font-family: Times New Roman, serif;
}

// ----------------------------------------------
// Color de la fuente
// ----------------------------------------------

.wfontColor-cverdeilusion,
.wfontColorEntradilla-cverdeilusion {
  color: #007b22;
}
.wfontColor-cnegro,
.wfontColorEntradilla-cnegro {
  color: #000;
}
.wfontColor-cblanco,
.wfontColorEntradilla-cblanco {
  color: #fff;
}
.wfontColor-cgris,
.wfontColorEntradilla-cgris {
  color: #7f7f7f;
}
.wfontColor-cgriscla,
.wfontColorEntradilla-cgriscla {
  color: #c3c3c3;
}
.wfontColor-cgranate,
.wfontColorEntradilla-cgranate {
  color: #990000;
}
.wfontColor-cmarron,
.wfontColorEntradilla-cmarron {
  color: #b97a57;
}
.wfontColor-crojo,
.wfontColorEntradilla-crojo {
  color: #ff3333;
}
.wfontColor-crosa,
.wfontColorEntradilla-crosa {
  color: #ffaec9;
}
.wfontColor-cnaranja,
.wfontColorEntradilla-cnaranja {
  color: #ff7f27;
}
.wfontColor-ctrina,
.wfontColorEntradilla-ctrina {
  color: #ffc90e;
}
.wfontColor-camarillo,
.wfontColorEntradilla-camarillo {
  color: #fff200;
}
.wfontColor-carena,
.wfontColorEntradilla-carena {
  color: #efe4b0;
}
.wfontColor-cverde,
.wfontColorEntradilla-cverde {
  color: #22b14c;
}
.wfontColor-clima,
.wfontColorEntradilla-clima {
  color: #b5e61d;
}
.wfontColor-ccyan,
.wfontColorEntradilla-ccyan {
  color: #00a2e8;
}
.wfontColor-ccyancla,
.wfontColorEntradilla-ccyancla {
  color: #99d9ea;
}
.wfontColor-cazul,
.wfontColorEntradilla-cazul {
  color: #3f48cc;
}
.wfontColor-cazulcla,
.wfontColorEntradilla-cazulcla {
  color: #7092be;
}
.wfontColor-cmorado,
.wfontColorEntradilla-cmorado {
  color: #a349a4;
}
.wfontColor-clavanda,
.wfontColorEntradilla-clavanda {
  color: #c8bfe7;
}
.wfontColor-cpanil,
.wfontColorEntradilla-cpanil {
  color: #003366;
}
.wfontColor-cpazul,
.wfontColorEntradilla-cpazul {
  color: #025fa5;
}
.wfontColor-cpverdeazul,
.wfontColorEntradilla-cpverdeazul {
  color: #395058;
}
.wfontColor-cpturquesa,
.wfontColorEntradilla-cpturquesa {
  color: #b6bfaa;
}

// ----------------------------------------------
// Color de fondo de los textos
// ----------------------------------------------

.wbackgroundColor-cverdeilusion {
  background-color: rgba(0, 123, 34, 0.8);
}
.wbackgroundColor-cnegro {
  background-color: rgba(0, 0, 0, 0.8)
}
.wbackgroundColor-cblanco {
  background-color: rgba(255,255,255,0.8);
}
.wbackgroundColor-cgris {
  background-color: rgba(127, 127, 127, 0.8);
}
.wbackgroundColor-cgriscla {
  background-color: rgba(195, 195, 195, 0.8);
}
.wbackgroundColor-cgranate {
  background-color: rgba(153, 0, 0, 0.8);
}
.wbackgroundColor-cmarron {
  background-color: rgba(185, 122, 87, 0.8);
}
.wbackgroundColor-crojo {
  background-color: rgba(255, 51, 51, 0.8);
}
.wbackgroundColor-crosa {
  background-color: rgba(255, 174, 201, 0.8);
}
.wbackgroundColor-cnaranja {
  background-color: rgba(255, 127, 39, 0.8);
}
.wbackgroundColor-ctrina {
  background-color: rgba(255, 201, 14, 0.8);
}
.wbackgroundColor-camarillo {
  background-color: rgba(255, 242, 0, 0.8);
}
.wbackgroundColor-carena {
  background-color: rgba(239, 228, 176, 0.8);
}
.wbackgroundColor-cverde {
  background-color: rgba(34, 177, 76, 0.8);
}
.wbackgroundColor-clima {
  background-color: rgba(181, 230, 29, 0.8);
}
.wbackgroundColor-ccyan {
  background-color: rgba(0, 162, 232, 0.8);
}
.wbackgroundColor-ccyancla {
  background-color: rgba(153, 217, 234, 0.8);
}
.wbackgroundColor-cazul {
  background-color: rgba(63, 72, 204, 0.8);
}
.wbackgroundColor-cazulcla {
  background-color: rgba(112, 146, 190, 0.8);
}
.wbackgroundColor-cmorado {
  background-color: rgba(163, 73, 164, 0.8);
}
.wbackgroundColor-clavanda {
  background-color: rgba(200, 191, 231, 0.8);
}
.wbackgroundColor-cpanil {
  background-color: rgba(0, 51, 102, 0.8);
}
.wbackgroundColor-cpazul {
  background-color: rgba(2, 95, 165, 0.8);
}
.wbackgroundColor-cpverdeazul {
  background-color: rgba(57, 80, 88, 0.8);
}
.wbackgroundColor-cpturquesa {
  background-color: rgba(182, 191, 170, 0.8)
}

// ----------------------------------------------
// Color en el formulario de creacion de la slide
// ----------------------------------------------

#form-widgets-font_color > option,
#form-widgets-font_color_entradilla > option,
#form-widgets-background_color > option {
  padding: 0.2em;
}
#form-widgets-font_color > option[value="cverdeilusion"],
#form-widgets-font_color_entradilla > option[value="cverdeilusion"],
#form-widgets-background_color > option[value="cverdeilusion"] {
  background-color: #007b22;
  color: white;
}

#form-widgets-font_color > option[value="cnegro"],
#form-widgets-font_color_entradilla > option[value="cnegro"],
#form-widgets-background_color > option[value="cnegro"] {
  background-color: #000;
  color: white;
}
#form-widgets-font_color > option[value="cblanco"],
#form-widgets-font_color_entradilla > option[value="cblanco"],
#form-widgets-background_color > option[value="cblanco"] {
  background-color: #fff;
  color: black;
}
#form-widgets-font_color > option[value="cgris"],
#form-widgets-font_color_entradilla > option[value="cgris"],
#form-widgets-background_color > option[value="cgris"] {
  background-color: #7f7f7f;
  color: white;
}
#form-widgets-font_color > option[value="cgriscla"],
#form-widgets-font_color_entradilla > option[value="cgriscla"],
#form-widgets-background_color > option[value="cgriscla"] {
  background-color: #c3c3c3;
}
#form-widgets-font_color > option[value="cgranate"],
#form-widgets-font_color_entradilla > option[value="cgranate"],
#form-widgets-background_color > option[value="cgranate"] {
  background-color: #990000;
  color: white;
}
#form-widgets-font_color > option[value="cmarron"],
#form-widgets-font_color_entradilla > option[value="cmarron"],
#form-widgets-background_color > option[value="cmarron"] {
  background-color: #b97a57;
}
#form-widgets-font_color > option[value="crojo"],
#form-widgets-font_color_entradilla > option[value="crojo"],
#form-widgets-background_color > option[value="crojo"] {
  background-color: #ff3333;
}
#form-widgets-font_color > option[value="crosa"],
#form-widgets-font_color_entradilla > option[value="crosa"],
#form-widgets-background_color > option[value="crosa"] {
  background-color: #ffaec9;
}
#form-widgets-font_color > option[value="cnaranja"],
#form-widgets-font_color_entradilla > option[value="cnaranja"],
#form-widgets-background_color > option[value="cnaranja"] {
  background-color: #ff7f27;
}
#form-widgets-font_color > option[value="ctrina"],
#form-widgets-font_color_entradilla > option[value="ctrina"],
#form-widgets-background_color > option[value="ctrina"] {
  background-color: #ffc90e;
}
#form-widgets-font_color > option[value="camarillo"],
#form-widgets-font_color_entradilla > option[value="camarillo"],
#form-widgets-background_color > option[value="camarillo"] {
  background-color: #fff200;
}
#form-widgets-font_color > option[value="carena"],
#form-widgets-font_color_entradilla > option[value="carena"],
#form-widgets-background_color > option[value="carena"] {
  background-color: #efe4b0;
}
#form-widgets-font_color > option[value="cverde"],
#form-widgets-font_color_entradilla > option[value="cverde"],
#form-widgets-background_color > option[value="cverde"] {
  background-color: #22b14c;
}
#form-widgets-font_color > option[value="clima"],
#form-widgets-font_color_entradilla > option[value="clima"],
#form-widgets-background_color > option[value="clima"] {
  background-color: #b5e61d;
}
#form-widgets-font_color > option[value="ccyan"],
#form-widgets-font_color_entradilla > option[value="ccyan"],
#form-widgets-background_color > option[value="ccyan"] {
  background-color: #00a2e8;
}
#form-widgets-font_color > option[value="ccyancla"],
#form-widgets-font_color_entradilla > option[value="ccyancla"],
#form-widgets-background_color > option[value="ccyancla"] {
  background-color: #99d9ea;
}
#form-widgets-font_color > option[value="cazul"],
#form-widgets-font_color_entradilla > option[value="cazul"],
#form-widgets-background_color > option[value="cazul"] {
  background-color: #3f48cc;
  color: white;
}
#form-widgets-font_color > option[value="cazulcla"],
#form-widgets-font_color_entradilla > option[value="cazulcla"],
#form-widgets-background_color > option[value="cazulcla"] {
  background-color: #7092be;
}
#form-widgets-font_color > option[value="cmorado"],
#form-widgets-font_color_entradilla > option[value="cmorado"],
#form-widgets-background_color > option[value="cmorado"] {
  background-color: #a349a4;
}
#form-widgets-font_color > option[value="clavanda"],
#form-widgets-font_color_entradilla > option[value="clavanda"],
#form-widgets-background_color > option[value="clavanda"] {
  background-color: #c8bfe7;
}
#form-widgets-font_color > option[value="cpanil"],
#form-widgets-font_color_entradilla > option[value="cpanil"],
#form-widgets-background_color > option[value="cpanil"] {
  background-color: #003366;
  color: white;
}
#form-widgets-font_color > option[value="cpazul"],
#form-widgets-font_color_entradilla > option[value="cpazul"],
#form-widgets-background_color > option[value="cpazul"] {
  background-color: #025fa5;
  color: white;
}
#form-widgets-font_color > option[value="cpverdeazul"],
#form-widgets-font_color_entradilla > option[value="cpverdeazul"],
#form-widgets-background_color > option[value="cpverdeazul"] {
  background-color: #395058;
  color: white;
}
#form-widgets-font_color > option[value="cpturquesa"],
#form-widgets-font_color_entradilla > option[value="cpturquesa"],
#form-widgets-background_color > option[value="cpturquesa"] {
  background-color: #b6bfaa;
}

/* ------------------- */
/* Controles SlideShow */
/* ------------------- */

ul.sshow-controls {
  display: block;
  background: transparent;
  background-image: none; /* safari bug */
  border: none;
  height: 0.1em;
  overflow: hidden;
  padding: 0;
  margin: -0.1em 0 0 -0.1em;
  width: 1px;
  // Se cambia a static porque desde accesibilidad piden que se muestre siempre
  //position: absolute;
  position: static;
}
ul.sshow-controls.showControls {
  list-style: none;
  display: block;
  height: auto;
  overflow: hidden;
  padding: 0;
  margin: 0;
  width: auto;
  position: static;
}

.hiddenStructure {
  display: block;
  background: transparent;
  background-image: none; /* safari bug */
  border: none;
  height: 0.1em;
  overflow: hidden;
  padding: 0;
  margin: -0.1em 0 0 -0.1em;
  width: 1px;
}

ul.sshow-controls li a {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  z-index: 999;
  position: absolute;
  top: 43%;
  padding: 1.4em 1.1em;
  border: 0.125rem solid transparent;

  &:active, &:focus, &:hover {
    background-color: white;
    color: $verde-ilusion;
    padding: 1.3em 1em;
    border: 0.125rem solid white;
    outline: 0.125rem solid $gray-900;
  }
}
ul.sshow-controls li.sshow-prev a {
  left: 0;
  &:active, &:focus, &:hover {padding-left: 1.1em;}
}
ul.sshow-controls li.sshow-next a {
  right: 0;

  &:active, &:focus, &:hover {padding-right: 1.1em;}
}

ul.sshow-controls.hiddenStructure a span.hiddenStructure {display: block !important; position: static !important;}

ul.sshow-controls li a span:after {
  content: "";
  width: 0.8em;
  height: 0.8em;
  border-style: solid;
  border-width: 0.2em 0.2em 0 0;
  display: block;
  position: absolute;
  top: 0.9em;
  border-color: white;
  z-index: 1;
}
ul.sshow-controls li.sshow-prev a span:after {
  transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  left: 0.9em;
}
ul.sshow-controls li.sshow-next a span:after {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  right: 0.9em;
}

ul.sshow-controls.hiddenStructure a span.hiddenStructure {
  display: block !important;
  position: static !important;
}

// Bullets
ol.sshow-markers {
  width: 100%;
  margin: auto;
  padding: 0;
  background-color: #f8f6f6;

  &.h-centered {
    text-align: center;
  }

  li {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    border-right: 0.125rem solid transparent;
    margin-bottom: -0.2em;
  }
  li a {
    display: inline-block;
    background-color: #f1f1f1;
    color: $once-text-color !important;
    font-weight: bold;
    padding: 0.4rem 2rem;
    box-sizing: border-box;
    vertical-align: middle;
    line-height: 100%;
    margin-bottom: 0.2em;
    text-decoration: none !important;
  }
  li.twoDigits a {
    padding: 0.4em 1.72em;
  }
  li.active-marker a {
    color: white !important;
    background-color: $gris-oscuro-40;
  }
  li a#play_control,
  li a#pause_control {
    background-color: $gris-oscuro-40;
    border-left: 0.125rem solid transparent;

    &:active, &:focus, &:hover {
      background-color: $verde-ilusion;
      border-left: 0.125rem solid white;
    }
  }

  li a#play_control img,
  li a#pause_control img {
    width: 1em;
    display: block;
  }

  li a:hover,
  li a:active,
  li a:focus {
    background-color: white;
    color: white !important ;
    border-bottom: 0.2em solid $gris-oscuro-40;
    margin-bottom: 0;
    text-decoration: none;
    padding-top: 0.275rem;
    margin-top: -0.125rem;
  }

  li.playpauseOver a {
    background-color: white;
    background-color: red;
  }
  li.playpauseOverOff a {
    background-color: #007b22;
  }

  .carousel-last-child & {
    position: static;
  }
}

p.sshow-caption {
  display: block;
  width: 96%;
  margin: 0;
  padding: 2%;
  position: absolute;
  bottom: 0;
}

/* Vista resumen Slide */

.template-view.portaltype-carousel_banner {
  .documentDescription + .field {
    margin-top: 1em;
  }
}

.carousel-banner-template-img-label i {
  margin-right: 0.5em;
}

.carousel-banner-template-img,
#content .carousel-banner-template-img {
  a {
    display: inline-block;
    border: 2px solid transparent;

    &:active, &:focus, &:hover {
      border-color: $verde-ilusion;
    }

    img {
      display: block;
    }
  }
}

/* --------------------- */
/* Ampliacion solo texto */
/* --------------------- */
/* VMEL20161201: De momento no lo hemos implementado */
/*.escritorio .textoAmpliado #portal-header-titleImageSS .wrap-content-right .titleImageSS-box-image{
-webkit-align-items: flex-end;-ms-flex-align:flex-end;align-items: flex-end;
}
.escritorio .textoAmpliado #portal-header-titleImageSS .wrap-content-left .titleImageSS-box-image{
-webkit-align-items: flex-start;-ms-flex-align:flex-start;align-items: flex-start;
}
.escritorio .textoAmpliado #portal-header-titleImageSS .wrap-content-right .titleImageSS-box-image img{margin-right:-14em;}
.escritorio .textoAmpliado #portal-header-titleImageSS .wrap-content-left .titleImageSS-box-image img{margin-left:-14em;}*/

// ------------------------
// Vista de Carousel Folder
// ------------------------

.portaltype-carousel_folder #content-core {
  margin-top: 1em;
}


/* -------------- */
/*  Media Queries */
/* -------------- */

@media screen and (max-width: 1151px) {
  .escritorio {
    #portal-header-titleImageSS {
      .titleImageSS-box-texts {
        padding: 0 1.1rem;
      }
      .titleImageSS-box-texts .box-texts-wrapper {
        max-width: none;
      }
      .wrap-content-center .titleImageSS-box-texts .box-texts-wrapper {
        padding-right: 28em;
      }

      .wvideo-left-up .titleImageSS-box-video .wrap-content,
      .wvideo-left-down .titleImageSS-box-video .wrap-content {
        left: 2em;
      }
      .wvideo-right-up .titleImageSS-box-video .wrap-content,
      .wvideo-right-down .titleImageSS-box-video .wrap-content {
        right: 2em;
      }

      .wrap-content-right .titleImageSS-box-image,
      .wrap-content-left .titleImageSS-box-image {
        -webkit-align-items: flex-end;
        -ms-flex-align: flex-end;
        align-items: flex-end;
      }
    }
  }

  ul.sshow-controls li a {
    padding: 1.3em 0.6em;

    &:active, &:focus, &:hover {padding: 1.2em 0.5em;}
  }

  ul.sshow-controls li.sshow-prev a {
    &:active, &:focus, &:hover {padding-left: 0.6em;}
  }
  ul.sshow-controls li.sshow-next a {
    &:active, &:focus, &:hover {padding-right: 0.6em;}
  }

  ul.sshow-controls li.sshow-prev a span:after {
    left: 0.35em;
  }
  ul.sshow-controls li.sshow-next a span:after {
    right: 0.35em;
  }
}

@media screen and (max-width: 1024px) {
  .escritorio {
    #portal-header-titleImageSS {
      .titleImageSS-box-texts .box-texts-wrapper {
        max-width: none;
      }
      .wrap-content-left
        .titleImageSS-box-texts
        .box-texts-wrapper
        .wrap-content {
        max-width: 19em;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .escritorio #portal-header-titleImageSS .box-texts-wrapper {
    position: relative;
    padding: 0;
  }
}

/* Estilos para movil */
@media screen and (max-width: 767px) {

    #portal-header-titleImageSS .titleImageSS-box-texts .box-texts-wrapper {bottom:2em;}

    #portal-header-titleImageSS
      .wrap-content-center
      .titleImageSS-box-texts
      .box-texts-wrapper {
      padding-right: 0;
    }
    #portal-header-titleImageSS .titleImageSS-box-video .wrap-content {
      text-align: left;
      padding-left: 1.1rem;
      bottom: 0;
      top: 0.8em;
    }

    #portal-header-titleImageSS
      .wvideo-left-up
      .titleImageSS-box-video
      .wrap-content,
    #portal-header-titleImageSS
      .wvideo-left-down
      .titleImageSS-box-video
      .wrap-content,
    #portal-header-titleImageSS
      .wvideo-center-up
      .titleImageSS-box-video
      .wrap-content,
    #portal-header-titleImageSS
      .wvideo-center-down
      .titleImageSS-box-video
      .wrap-content,
    #portal-header-titleImageSS
      .wvideo-right-up
      .titleImageSS-box-video
      .wrap-content,
    #portal-header-titleImageSS
      .wvideo-right-down
      .titleImageSS-box-video
      .wrap-content {
      left: 0;
      top: 1.1rem;
    }

    #portal-header-titleImageSS .wrap-content-left .titleImageSS-box-link,
    #portal-header-titleImageSS .wrap-content-center .titleImageSS-box-link,
    #portal-header-titleImageSS .wrap-content-right .titleImageSS-box-link {
      bottom: 1.85em;
      text-align: left;
      padding: 0;
    }

    #portal-header-titleImageSS .titleImageSS-box-link a.carouselTextLink,
    #portal-header-titleImageSS .titleImageSS-box-link span.carouselTextLink {
      padding-left: 1.1rem;
    }

    .carousel-last-child #portal-header-titleImageSS:before {
      background-image: url(triangle-white-up.png);
    }

    .carousel-last-child ol.sshow-markers {
      position: absolute;
    }

  ol.sshow-markers {
    position: absolute;
    bottom:0;
    z-index: 3;
    left: 0;
    right: 0;
    padding:0.3em 0;
    background-color: transparent;

    li:not(#main-control) {

        margin:0 0.3em;
        border: none;

        a {
            background-color: white;
            padding: 0.3em;
            margin: 0;


            @include link-haf {
                background-color: $verde-ilusion;
                border-color:white;
            }

            .slidenumber {display:none;visibility:hidden;}
        }

        &.active-marker a {
            background-color: $amarillo;
            border: 0.125rem solid $amarillo;
        }
    }

    #main-control {
        // VMEL 17092019: Comento las siguientes lineas porque piden que se oculte
        // Eliminar si en un tiempo no se ha cambiado de opinion
        //position: absolute;
        //right: 1.7em;
        //bottom: 1.7em;
        //margin: 0;
        display: none;
        visibility: hidden;
    }
  }

}